<template>
    <div>

        <Head :title="'OA审核详情'" :out="'OA'" />
        <!-- @rendered="tabsChange" @click="tabsChange" -->
        <van-list :finished="true" :style="{ height: listHeight + 'px', overflow: 'auto', margin: '5px', marginTop:'50px' }">

            <van-collapse v-model="activeNames" v-if="type==3">

                <van-collapse-item title="关联信息" name="0">
                    <van-row :style="{margin:'15px'}">
                        <van-col span="11">
                            <van-button plain type="primary" size="large" :style="{color:'black'}" @click="openPopup(1)">
                                车辆登记资料
                            </van-button>
                        </van-col>

                        <van-col span="2">
                            
                        </van-col>

                        <van-col span="11">
                            <van-button plain type="primary" size="large" :style="{color:'black'}" @click="openPopup(2)">
                                车辆采购资料
                            </van-button>
                        </van-col>
                    </van-row>
                </van-collapse-item>

                <van-collapse-item :title="'质检增费总额：'+pageData.eInfo.quality_price+'元'" name="1">
                    <div class="table" v-if="pageData.quality_price_detail.length!=0">
                        <table >
                            <tr>
                                <td>项目</td>
                                <td>单价</td>
                                <td>数量</td>
                                <td>增费</td>
                            </tr>
                            <tr v-for="(i,index) in pageData.quality_price_detail" :key="i.id">
                                <td>{{i.name}}</td>
                                <td>{{i.unit_price}}</td>
                                <td>{{i.num}}</td>
                                <td>{{i.num * i.unit_price}}</td>
                            </tr>
                        </table>
                    </div>
                </van-collapse-item>

                <van-collapse-item :title="'质检扣费总额：'+pageData.eInfo.quality_price_deduction+'元'" name="2">
                    <div class="table" v-if="pageData.quality_price_deduction_detail.length!=0">
                        <table >
                            <tr>
                                <td>项目</td>
                                <td>单价</td>
                                <td>数量</td>
                                <td>扣费</td>
                            </tr>
                            <tr v-for="(i,index) in pageData.quality_price_deduction_detail" :key="i.id">
                                <td>{{i.name}}</td>
                                <td>{{i.unit_price}}</td>
                                <td>{{i.num}}</td>
                                <td>{{i.num * i.unit_price}}</td>
                            </tr>
                        </table>
                    </div>
                </van-collapse-item>


                <van-collapse-item :title="'过磅计重应付：'+pageData.eInfo.purchase_amount+'元'" name="3">
                    <div :style="{ margin: '10px 5px' }">
                        <span>过磅重量：</span>
                        <span>{{pageData.eInfo.batch_average_weight}}</span>
                        <span>kg</span>
                    </div>
                    <div :style="{ margin: '10px 5px' }">
                        <span>质检扣杂：</span>
                        <span>{{pageData.eInfo.suttle_batch_average_weight}}</span>
                        <span>kg</span>
                    </div>
                    <div :style="{ margin: '10px 5px' }">
                        <span>计费净重：</span>
                        <span>{{pageData.eInfo.batch_average_weight - pageData.eInfo.suttle_batch_average_weight}}</span>
                        <span>kg</span>
                    </div>
                    <div :style="{ margin: '10px 5px' }">
                        <span>采购单价：</span>
                        <span>{{pageData.eInfo.unit_price}}</span>
                        <span>元/吨</span>
                    </div>
                    <div :style="{ margin: '10px 5px' }">
                        <span>车辆应付金额：</span>
                        <span>{{pageData.eInfo.purchase_amount}}</span>
                        <span>元</span>
                    </div>
                </van-collapse-item>
                
                <van-collapse-item :title="'运输成本'+(pageData.eInfo.shipping_cost_type==1?'收入：':'支出：')+pageData.eInfo.shipping_cost+'元'" name="4">
                    
                </van-collapse-item>
                <van-collapse-item :title="'其他费用应付：'+pageData.eInfo.other_cost+'元'" name="5">
                </van-collapse-item>
                <van-collapse-item title="" name="6" :disabled="true">
                    <div :style="{ margin: '10px 5px',color:'red',textAlign:'right',fontWeight:'bolder'}">
                        <span :style="{fontSize:'150%'}">应付合计：</span>
                        <span :style="{fontSize:'150%'}">{{parseFloat(pageData.eInfo.purchase_amount) + parseFloat(pageData.eInfo.other_cost) + (parseFloat(pageData.eInfo.shipping_cost_type)==1?(0-parseFloat(pageData.eInfo.shipping_cost)):parseFloat(pageData.eInfo.shipping_cost)) + parseFloat(pageData.eInfo.quality_price) - parseFloat(pageData.eInfo.quality_price_deduction)}}</span>
                        <span :style="{fontSize:'150%'}">元</span>
                    </div>
                </van-collapse-item>
            </van-collapse>
            <vehicleInfo v-if="type==2" ref="vehicleInfoRef"/>
            <vehiclePurchase v-if="type==1" ref="vehiclePurchaseRef"/>

        </van-list>
        
        <div :style="{margin:'20px'}">
            <van-row>
                <van-col span="10">
                    <van-button  type="danger" size="large" @click="passOk(3)">
                        审批拒绝
                    </van-button>
                </van-col>
                <van-col span="4">
                </van-col>
                <van-col span="10">
                    <van-button v-if="!islastlevel" type="primary" size="large" @click="passOk(2)">
                        转下级审批
                    </van-button>
                    <van-button v-if="islastlevel" type="primary" size="large" @click="passOk(4)">
                        审批完结
                    </van-button>
                </van-col>
            </van-row>
        </div>

        <van-popup
            v-model="isPopupShow"
            :closeable="false"
            :close-on-click-overlay="false"
            position="bottom"
            :style="{
                height: '100%',
            }"
        >
                <vehicleInfo :ispopup="true" v-if="popupId==1" ref="vehicleInfoRef" @closePopup="closePopup"  />
                <vehiclePurchase v-if="popupId==2" ref="vehiclePurchaseRef" @closePopup="closePopup"  />
                
        </van-popup>


        <van-dialog width="90%" v-model="showSign" title="请您签字" show-cancel-button @confirm="confirmSign">
            <div>
                    
                    <div v-if="signature_image" class="img"> 
                        <van-image  :src="signature_image" />
                    </div>
                    <div  v-if="!signature_image">
                        <signature ref="tureRef" @isFile="isFile"  />
                    </div>
                    <van-button  type="danger" size="large" @click="chu" :style="{marginBottom:'15px'}">
                        清除签名
                    </van-button>
                    <hr/>
                </div>
        </van-dialog>




        <van-dialog width="90%" v-model="showSubmitConfirm" :title="status==3?'填写拒绝原因':'请您确认'" show-cancel-button @confirm="confirmSubmit">
            <div v-if="status==3" :style="{textAlign:'center',marginTop:'20px',marginBottom:'20px'}">
                <van-field v-model="remark" label="拒绝原因：" placeholder="请输入拒绝原因"/>
            </div>
            <div v-if="status==2" :style="{textAlign:'center',marginTop:'20px',marginBottom:'20px'}">
                您确认通过审核并转下级审批？
            </div>
            <div v-if="status==4" :style="{textAlign:'center',marginTop:'20px',marginBottom:'20px'}">
                您确认通过审核？
            </div>
        </van-dialog>


        <van-overlay :show="showOverlay">
            <div class="wrapper">
                    <van-loading size="2rem" vertical color="#fff" text-size="0.5rem">加载中...</van-loading>
            </div>
        </van-overlay>
    </div>
    
</template>

<script>
import Head from '@/components/Head'
import { Toast } from 'vant'
import { encipherMent, encryptDecode } from '@/utils/encrypt'
import { is_post,post } from '@/Http/api' 
import vehicleInfo from '@/components/defaultInfo/vehicleInfo'
import vehiclePurchase from '@/components/defaultInfo/vehiclePurchase'
import signature from '@/components/signature'
export default {
    components: {
        Head,vehicleInfo,vehiclePurchase,signature
    },
    data() {
        return {
            showSubmitConfirm:false,
            showSign:false,
            type:0,
            showOverlay:false,
            islastlevel:false,
            popupId:null,
            isPopupShow:false,
            activeNames: ['0','6'],
            listHeight: 0,
            active: 0,
            list: [],
            loading: false,
            finished: false,
            refreshing: false,
            pageData:{},
            signature_image:"",
            status:0,
            remark:""
        }
    },
    mounted() {
        this.listHeight = document.documentElement.clientHeight * 0.8
        console.log(this.listHeight)
        this.type=this.$route.query.t
        this.getNextLevel()
        if(this.type==3){
            this.showOverlay=true
            this.getInfo()
        }
    },
    methods: {
        openSign(){
            let params={id:this.$route.query.id}
            this.$http.post("/index.php/approval/getUser", params).then((res) => {
                if(res.data.code == 0){
                    this.signature_image=res.data.data
                }
                this.showSign=true
            })
        },
        async isFile(e){
            let params = new FormData(); //创建form对象
            params.append("file", e);
            this.$http.post("/index.php/index/upload/index", params).then((res) => {
                if(res.data.code == 0){
                    console.log(res.data.data)
                    this.signature_image=res.data.data
                    this.SubmitConfirm(this.status)
                }else{
                    this.$toast.fail(res.data.msg)
                }
            })
        },
        confirmSign(){
            if(this.signature_image){
                this.SubmitConfirm(this.status)
            }else{
                this.$refs.tureRef.saveSignImg('NoJY')
            }
        },
        chu(){
                if(this.signature_image){
                    this.signature_image = ''
                }else{
                    this.$refs.tureRef.clearSignImg()
                }
            },
        async passOk(status){
            /* '/index.php/approval/audit',{'id':this.source_id,status:num,remark:this.remark,signature_image} */
            this.remark=""
            this.status=status
            let type=this.$route.query.t
            if(type==3){
                //唤醒签名
                this.openSign()
            }else{
                this.SubmitConfirm()
            }
            
        },
        SubmitConfirm(){
            this.showSubmitConfirm=true
        },
        async confirmSubmit(){
            if(this.status==3){
                if(this.remark==""){
                    this.$toast.fail("请输入拒绝原因")
                    return false
                }
                
            }
            
            let url="/index.php/approval/audit"
            let param = new URLSearchParams();
            param.append("id",this.$route.query.id);
            param.append("status",this.status);
            param.append("remark",this.remark);
            param.append("signature_image",this.signature_image);

            let result=await post(url,param).then(res=>res)
            console.log('result.data',result.data)
            if(result.data.code==0){
                this.$router.push({path:"/OA"})
            }
        },
        async getNextLevel(){
            let url="/index.php/approval/nextlevel"
            let data = encipherMent( JSON.stringify({"id":this.$route.query.id}))
            let param = new URLSearchParams();
            param.append("value",data);
            let result=await is_post(url,param).then(res=>res)
            console.log(result.data)
            this.islastlevel=result.data
        },
        async getInfo(){
            let url="/index.php/approval/getInfo"
            let param = {id:this.$route.query.source_id};

            let result=await post(url,param).then(res=>res)
            console.log('result.data',result.data)
            if(result.data.code==0){
                this.pageData=result.data.data
            }else{
                this.$toast.fail(result.data.msg)
                this.$router.push({path:"/OA"})
            }
            this.showOverlay=false 
        },
        closePopup(){
            this.isPopupShow = false
            
        },
        openPopup(id){
            this.popupId=id
            this.isPopupShow = true
        },
        
    },
}

</script>

<style lang="scss" scoped>
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .table{
    padding:10px;
    margin-top: 10px;
    table,table tr th, table tr td { border:1px solid #000; }
    table { 
        width: 100%;
        text-align: center; 
        border-collapse: collapse; 
        
    }
}
</style>