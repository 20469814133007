<template>
    <div>
        <Head v-if="ispopup" :title="'车辆采购单信息'" :isPopup="true" @closePopup="$emit('closePopup')" />
        <van-list :finished="true" :style="{margin: '5px', marginTop:ispopup?'50px':'5px' }">
            <van-collapse v-model="activeNames" >
                <van-collapse-item title="自定义信息" name="1" >
                    <div :style="{ margin: '10px 5px' }">
                        <span>进厂编号：</span>
                        <span>{{pageData.defined_num}}</span>
                    </div>
                    <div :style="{ margin: '10px 5px' }" v-if="pageData.purchaseType==2">
                        <span>自定义车辆类型：</span>
                        <span>{{defined_type[pageData.defined_num_id]}}</span>
                    </div>
                </van-collapse-item>
                <van-collapse-item title="车辆信息" name="1" >
                    <div :style="{ margin: '10px 5px' }">
                        <span>车辆所有人：</span>
                        <span>{{pageData.carUser}}</span>
                    </div>
                    <div :style="{ margin: '10px 5px' }" v-if="pageData.purchaseType==2">
                        <span>号牌类型：</span>
                        <span>{{pageData.PlateTypeIdText}}</span>
                    </div>
                    <div :style="{ margin: '10px 5px' }" v-if="pageData.purchaseType==2">
                        <span>车牌号码：</span>
                        <span>{{pageData.carNumberPlate}}</span>
                    </div>
                    <div :style="{ margin: '10px 5px' }" v-if="pageData.purchaseType==2">
                        <span>车架号码：</span>
                        <span>{{pageData.vin}}</span>
                    </div>
                    <div :style="{ margin: '10px 5px' }" v-if="pageData.purchaseType==2">
                        <span>报废类型：</span>
                        <span>{{pageData.VehicleTypeText}}</span>
                    </div>
                    <div :style="{ margin: '10px 5px' }" v-if="pageData.purchaseType==2">
                        <span>车辆采购备注：</span>
                        <span>{{pageData.remark}}</span>
                    </div>
                </van-collapse-item>
                <van-collapse-item title="车辆图片" name="1" >
                    <div :style="{ margin: '10px 5px' }" v-for="(img,idx) in pageData.image.split(',')" :key="idx">
                        <van-image radius="10" fit="fill" :src="img" @click="imagePreview"/>
                    </div>
                </van-collapse-item>
                <van-collapse-item title="采购方式" name="1" >
                    <div :style="{ margin: '10px 5px' }">
                        <span>采购类型：</span>
                        <span v-if="pageData.purchaseType==1">普通采购</span>
                        <span v-if="pageData.purchaseType==2">渠道采购</span>
                    </div>
                    <div :style="{ margin: '10px 5px' }" v-if="pageData.purchaseType==2">
                        <span>采购渠道：</span>
                        <span>{{pageData.PurchasingChannelsIdText}}</span>
                    </div>
                </van-collapse-item>
                <van-collapse-item title="联系人信息" name="1" >
                    <div :style="{ margin: '10px 5px' }">
                        <span>联系人类型：</span>
                        <span v-if="pageData.contactType==1">车主</span>
                        <span v-if="pageData.contactType==2">代理人</span>
                    </div>
                    <div :style="{ margin: '10px 5px' }">
                        <span>联系人姓名：</span>
                        <span>{{pageData.carOwner}}</span>
                    </div>
                    <div :style="{ margin: '10px 5px' }">
                        <span>联系人电话：</span>
                        <span>{{pageData.carOwnerPhone}}</span>
                    </div>
                    <div :style="{ margin: '10px 5px' }">
                        <span>开户银行：</span>
                        <span>{{pageData.opening_bank}}</span>
                    </div>
                    <div :style="{ margin: '10px 5px' }">
                        <span>银行账号：</span>
                        <span>{{pageData.bank_card_number}}</span>
                    </div>
                    <div :style="{ margin: '10px 5px' }">
                        <span>开户姓名：</span>
                        <span>{{pageData.bank_user}}</span>
                    </div>
                </van-collapse-item>
                <van-collapse-item title="采购信息" name="1" >
                    <div :style="{ margin: '10px 5px' }">
                        <span>采购负责人：</span>
                        <span>{{pageData.principalUserIdText}}</span>
                    </div>
                    <div :style="{ margin: '10px 5px' }">
                        <span>统货单价/吨：</span>
                        <span>{{statistics_price[pageData.statistics_price_id]}}</span>
                    </div>
                    <div :style="{ margin: '10px 5px' }">
                        <span>预计重量（Kg）：</span>
                        <span>{{pageData.beforPurchaseWeight}}</span>
                    </div>
                    <div :style="{ margin: '10px 5px' }">
                        <span>运送方式：</span>
                        <span>{{pageData.DeliveryTypeIdText}}</span>
                    </div>
                    <div :style="{ margin: '10px 5px' }">
                        <span>预计采购成本：</span>
                        <span>{{pageData.purchaseAmount}}</span>
                    </div>
                    <div :style="{ margin: '10px 5px' }">
                        <span>预计物流成本：</span>
                        <span>{{pageData.logisticsCost}}</span>
                    </div>
                    <div :style="{ margin: '10px 5px' }">
                        <span>预计其他成本：</span>
                        <span>{{pageData.otherCosts}}</span>
                    </div>
                    <div :style="{ margin: '10px 5px' }">
                        <span>付款方式：</span>
                        <span v-if="pageData.payType==1">车辆到场过磅付款</span>
                        <span v-if="pageData.payType==2">按照采购合同要求付款</span>
                        <span v-if="pageData.payType==3">销户完成后付款</span>
                    </div>
                    <div :style="{ margin: '10px 5px' }">
                        <span>是否提供发票：</span>
                        <span v-if="pageData.isInvoice==1">是</span>
                        <span v-if="pageData.isInvoice==2">否</span>
                    </div>
                </van-collapse-item>
            </van-collapse>
        </van-list>
        <van-overlay :show="showOverlay">
            <div class="wrapper">
                    <van-loading size="2rem" vertical color="#fff" text-size="0.5rem">加载中...</van-loading>
            </div>
        </van-overlay>
    </div>
</template>

<script>
import Head from '@/components/Head'
import { Toast,ImagePreview } from 'vant'
import { encipherMent, encryptDecode } from '@/utils/encrypt'
import { is_post,post } from '@/Http/api'
export default {
    components: {
        Head,
    },
    props:['source_id','ispopup'],
    data() {
        return {
            showOverlay:true,
            activeNames: ['1','2','3','4','5','6','7'],
            listHeight: 0,
            active: 0,
            list: [],
            loading: false,
            finished: false,
            refreshing: false,
            pageData:{},
            statistics_price:{},
        }
    },
    mounted() {
        this.listHeight = document.documentElement.clientHeight * 0.8
        console.log(this.listHeight)
        this.getInfo()
    },
    methods: {
        imagePreview(){
            let urls=[this.images.vehicleImages.split(',')[0]]
            ImagePreview(urls)
        },
        async getInfo(){
            this.getDefinedType()
            this.getStatisticsPrice()
            let url="/index.php/index/End_Of_Life_Vehicles/get"
            let data = encipherMent( JSON.stringify({end_of_lifeVehiclesId:this.$route.query.source_id}))
            let param = new URLSearchParams();
            param.append("value",data);
            let result=await is_post(url,param).then(res=>res)
            console.log(result.data)
            this.pageData=result.data
            this.showOverlay=false
        },
        async getDefinedType(){
            let url="/index.php/defined_num/_list"
            let param = new URLSearchParams();
            let {data}=await post(url,param).then(res=>res)
            let defined_type={}
            data.data.forEach( (item,index)=> {
                defined_type[item.id]=item.defined_type
            });
            this.defined_type=defined_type
            console.log('this.defined_type',JSON.stringify(this.defined_type))
        },


        async getStatisticsPrice(){
            let url="index.php/statistics_price/_list"
            let param = {};
            let {data}=await post(url,param).then(res=>res)
            let statistics_price={}
            data.data.forEach( (item,index)=> {
                statistics_price[item.id]="["+item.name+"]"+" - "+item.price
            });
            this.statistics_price=statistics_price
            console.log('this.statistics_price',JSON.stringify(this.statistics_price))
        },
        
    },

    
}

</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>