<template>
    <div>
        <Head v-if="ispopup" :title="'车辆登记信息'" :isPopup="true" @closePopup="$emit('closePopup')" />
        <van-list :finished="true" :style="{margin: '5px', marginTop:ispopup?'50px':'5px' }">
            <van-collapse v-model="activeNames">
                <van-collapse-item title="进厂自定义信息" name="1">
                    <div :style="{ margin: '10px 5px' }">
                        <span>自定义进厂编号：</span>
                        <span>{{pageData.defined_num}}</span>
                    </div>
                    <div :style="{ margin: '10px 5px' }">
                        <span>自定义车辆类型：</span>
                        <span>{{defined_type[pageData.defined_num_id]}}</span>
                    </div>
                    <div :style="{ margin: '10px 5px' }">
                        <span>采购渠道：</span>
                        <span>{{pageData.PurchasingChannelsIdText}}</span>
                    </div>
                    <div :style="{ margin: '10px 5px' }">
                        <span>是否监销：</span>
                        <span v-if="pageData.is_sale_of==1">是</span>
                        <span v-if="pageData.is_sale_of==2">否</span>
                    </div>
                </van-collapse-item>
                <van-collapse-item title="车辆报废类型" name="2">
                    <div :style="{ margin: '10px 5px' }">
                        <span>车辆报废类型：</span>
                        <span>{{vehicle_type[pageData.vehicleTypeId]}}</span>
                    </div>
                </van-collapse-item>
                <van-collapse-item title="车主信息" name="3">
                    <div :style="{ margin: '10px 5px' }">
                        <span>证件类型：</span>
                        <span>{{id_type[pageData.IDtype_id]}}</span>
                    </div>
                    <div :style="{ margin: '10px 5px' }">
                        <span>车主名称：</span>
                        <span>{{pageData.type_value}}</span>
                    </div>
                    <div :style="{ margin: '10px 5px' }">
                        <span>证件号码：</span>
                        <span>{{pageData.IDtype_id_value}}</span>
                    </div>
                    <div :style="{ margin: '10px 5px' }">
                        <span>联系电话：</span>
                        <span>{{pageData.phone}}</span>
                    </div>
                    <div :style="{ margin: '10px 5px' }">
                        <span>邮政编码：</span>
                        <span>{{pageData.postal_code}}</span>
                    </div>
                    <div :style="{ margin: '10px 5px' }">
                        <span>联系住址：</span>
                        <span>{{pageData.address}}</span>
                    </div>
                </van-collapse-item>
                <van-collapse-item title="经办人信息" name="4">
                    <div :style="{ margin: '10px 5px' }">
                        <span>经办人姓名：</span>
                        <span>{{pageData.agentName}}</span>
                    </div>
                    <div :style="{ margin: '10px 5px' }">
                        <span>经办人身份证号：</span>
                        <span>{{pageData.agentName_ID}}</span>
                    </div>
                </van-collapse-item>

                <van-collapse-item title="车辆信息" name="5">
                    <div :style="{ margin: '10px 5px' }">
                        <span>车架号码：</span>
                        <span>{{pageData.vin}}</span>
                    </div>
                    <div :style="{ margin: '10px 5px' }">
                        <span>牌照号码：</span>
                        <span>{{pageData.carNumberPlate}}</span>
                    </div>
                    <div :style="{ margin: '10px 5px' }">
                        <span>车辆类型：</span>
                        <span>{{pageData.carType_text}}</span>
                    </div>
                    <div :style="{ margin: '10px 5px' }">
                        <span>动力类别：</span>
                        <span>{{fuel_type[pageData.fuelTypeId]}}</span>
                    </div>
                    <div :style="{ margin: '10px 5px' }">
                        <span>核定载客数：</span>
                        <span>{{pageData.approvedPassengersCapacity}}</span>
                    </div>
                    <div :style="{ margin: '10px 5px' }">
                        <span>使用性质：</span>
                        <span>{{pageData.useAttributeId_text}}</span>
                    </div>
                    <div :style="{ margin: '10px 5px' }">
                        <span>车辆品牌：</span>
                        <span>{{pageData.vehicleBrand}}</span>
                    </div>
                    <div :style="{ margin: '10px 5px' }">
                        <span>车辆型号：</span>
                        <span>{{pageData.vehicleModel}}</span>
                    </div>
                    <div :style="{ margin: '10px 5px' }">
                        <span>发动机型号：</span>
                        <span>{{pageData.engineModel}}</span>
                    </div>
                    <div :style="{ margin: '10px 5px' }">
                        <span>发动机号码：</span>
                        <span>{{pageData.engineNumber}}</span>
                    </div>
                    <div :style="{ margin: '10px 5px' }">
                        <span>变速器型号：</span>
                        <span>{{pageData.transmission_model}}</span>
                    </div>
                    <div :style="{ margin: '10px 5px' }">
                        <span>注册登记日期：</span>
                        <span>{{pageData.registrationTime}}</span>
                    </div>
                    <div :style="{ margin: '10px 5px' }">
                        <span>整备质量：</span>
                        <span>{{pageData.curbWeight}}</span>
                        <span :style="{color:'red'}"> kg</span>
                    </div>
                    <div :style="{ margin: '10px 5px' }">
                        <span>排量/功率：</span>
                        <span>{{pageData.displacement}}</span>
                        <span :style="{color:'red'}">ml / </span>
                        <span>{{pageData.power}}</span>
                        <span :style="{color:'red'}">kw</span>
                    </div>
                    <div :style="{ margin: '10px 5px' }">
                        <span>外廓尺寸：</span>
                        <span :style="{color:'red'}">长</span>
                        <span>{{pageData.length}}</span>
                        <span :style="{color:'red'}">宽</span>
                        <span>{{pageData.width}}</span>
                        <span :style="{color:'red'}">高</span>
                        <span>{{pageData.height}}</span>
                    </div>
                    <div :style="{ margin: '10px 5px' }">
                        <span>总里程数：</span>
                        <span>{{pageData.mileage}}</span>
                    </div>
                </van-collapse-item>

                <van-collapse-item title="45度车辆影像" name="6">
                    <div :style="{ margin: '10px 5px' }">
                        <van-image radius="10" fit="fill" :src="images.vehicleImages.split(',')[0]" @click="imagePreview"/>
                    </div>
                </van-collapse-item>
            </van-collapse>
        </van-list>
        <van-overlay :show="showOverlay">
            <div class="wrapper">
                    <van-loading size="2rem" vertical color="#fff" text-size="0.5rem">加载中...</van-loading>
            </div>
        </van-overlay>
    </div>
</template>

<script>
import Head from '@/components/Head'
import { Toast,ImagePreview } from 'vant'
import { encipherMent, encryptDecode } from '@/utils/encrypt'
import { is_post,post,encrypt_post } from '@/Http/api'
export default {
    components: {
        Head,
    },
    props:['source_id','ispopup'],
    data() {
        return {
            showOverlay:true,
            activeNames: ['1','2','3','4','5','6'],
            listHeight: 0,
            active: 0,
            list: [],
            loading: false,
            finished: false,
            refreshing: false,
            pageData:{},
            defined_type:{},
            vehicle_type:{},
            id_type:{},
            fuel_type:{},
            images:{
                vehicleImages:""
            }
        }
    },
    mounted() {
        this.listHeight = document.documentElement.clientHeight * 0.8
        console.log(this.listHeight)
        this.getInfo()
    },
    methods: {
        imagePreview(){
            let urls=[this.images.vehicleImages.split(',')[0]]
            ImagePreview(urls)
        },
        async getInfo(){
            this.getDefinedType()
            this.getVehicleType()
            this.getIDtype()
            this.getFuelType()
            let url="/index.php/vehicles/get"
            let data = encipherMent( JSON.stringify({id:this.$route.query.source_id}))
            let param = new URLSearchParams();
            param.append("value",data);
            let result=await encrypt_post(url,param).then(res=>res)
            console.log("vehicleInfo",result)
            if(result.code==0){
                this.pageData=result.data.data
                this.images=result.data.img
            }else{
                this.$router.push({path:"/OA"})
                this.$toast.fail(result.msg)
            }
            this.showOverlay=false
        },
        async getDefinedType(){
            let url="/index.php/defined_num/_list"
            let param = new URLSearchParams();
            let {data}=await post(url,param).then(res=>res)
            let defined_type={}
            data.data.forEach( (item,index)=> {
                defined_type[item.id]=item.defined_type
            });
            this.defined_type=defined_type
            console.log('this.defined_type',JSON.stringify(this.defined_type))
        },

        async getVehicleType(){
            let url="/index.php/index/Simply"
            let param = new URLSearchParams();
            param.append("type","vehicleType");
            let {data}=await post(url,param).then(res=>res)
            let vehicle_type={}
            data.data.forEach( (item,index)=> {
                vehicle_type[item.id]=item.name
            });
            this.vehicle_type=vehicle_type
            console.log('this.vehicle_type',JSON.stringify(this.vehicle_type))
        },


        async getIDtype(){
            let url="/index.php/index/Simply"
            let param = new URLSearchParams();
            param.append("type","IDtype");
            let {data}=await post(url,param).then(res=>res)
            let id_type={}
            data.data.forEach( (item,index)=> {
                id_type[item.id]=item.name
            });
            this.id_type=id_type
            console.log('this.id_type',JSON.stringify(this.id_type))
        },
        async getFuelType(){
            let url="/index.php/index/Simply"
            let param = new URLSearchParams();
            param.append("type","fuelType");
            let {data}=await post(url,param).then(res=>res)
            let fuel_type={}
            data.data.forEach( (item,index)=> {
                fuel_type[item.id]=item.name
            });
            this.fuel_type=fuel_type
            console.log('this.fuel_type',JSON.stringify(this.fuel_type))
        },
        

        
        
    },

}

</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

</style>